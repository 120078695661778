<template>
  <div style="padding: 20px">
    <div style="margin-bottom: 20px">
      <select-area @areaListId="getAreaListId"/>
      <el-select v-model="query.state" placeholder="订单状态" class="handle-select mr10">
        <el-option key="已完成" label="已完成" value="已完成"></el-option>
        <el-option key="已拒绝" label="已拒绝" value="已拒绝"></el-option>
        <el-option key="停用中" label="停用中" value="停用中"></el-option>
        <el-option key="待审核" label="待审核" value="待审核"></el-option>
      </el-select>
      <el-input v-model="query.coachName" placeholder="教练姓名" style="width: 160px" class="mr10"></el-input>
      <el-input v-model="query.studentName" placeholder="学生姓名" style="width: 180px"
                class="mr10"></el-input>

      <el-button type="primary" icon="Search" style="margin-left: 10px" @click="query.pageIndex=0,getData()">搜索
      </el-button>
    </div>

    <el-table v-loading="tableLoading" :data="stopOrderList" class="table" ref="multipleTable"
              :row-style="{height:0+'px'}" :cell-style="{padding:0+'px'}" height="606px"
              header-cell-class-name="table-header" @selection-change="handleSelectionChange">
      <el-table-column show-overflow-tooltip="true" prop="studentName" label="学生姓名" width="100"></el-table-column>
      <el-table-column label="用户手机号" width="140">
        <template #default="scope">
          <div style="display: flex">
            <div style="width: 150px">
              <span v-if="scope.row.studentPhone!=notEncryptId">{{ filterTel(scope.row.studentPhone) }}</span>
              <span v-else>{{ scope.row.studentPhone }}</span>
            </div>
            <div>
              <span @click="viewPhone(scope.row.studentPhone)">
                <svg v-if="scope.row.studentPhone!=notEncryptId" t="1644395285994" style="width: 20px;height: 20px"
                     class="icon"
                     viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="2629" data-spm-anchor-id="a313x.7781069.0.i16" width="200"
                     height="200">
                  <path
                      d="M660.8 680.4c-10.1 0-20-5.2-25.5-14.4l-59.4-99.1c-8.4-14.1-3.9-32.3 10.2-40.8 14.1-8.4 32.3-3.8 40.8 10.2l59.4 99.1c8.4 14.1 3.9 32.3-10.2 40.8-4.8 2.9-10.1 4.2-15.3 4.2zM819.1 581.3c-7.6 0-15.2-2.9-21-8.7l-79.2-79.3c-11.6-11.6-11.6-30.4 0-42 11.6-11.6 30.4-11.6 42 0l79.2 79.3c11.6 11.6 11.6 30.4 0 42-5.8 5.9-13.4 8.7-21 8.7zM363.2 680.4c-5.2 0-10.5-1.3-15.3-4.2-14.1-8.4-18.6-26.7-10.2-40.8l59.4-99.1c8.4-14.1 26.7-18.7 40.8-10.2 14.1 8.4 18.6 26.7 10.2 40.8L388.7 666c-5.6 9.3-15.4 14.4-25.5 14.4zM204.9 581.3c-7.6 0-15.2-2.9-21-8.7-11.6-11.6-11.6-30.4 0-42l79.2-79.3c11.6-11.6 30.4-11.6 42 0 11.6 11.6 11.6 30.4 0 42l-79.2 79.3c-5.8 5.9-13.4 8.7-21 8.7z"
                      p-id="2630"></path>
                  <path
                      d="M521.6 561.5c-82.8 0-204.7-28.4-314.9-163.5-1.3-1.6-2.3-2.8-3-3.6-10.9-10.8-11.8-28.4-1.7-40.3 10.6-12.5 29.4-14.1 41.9-3.5 1.3 1.1 4.4 4.3 8.9 9.8 95.5 117.1 198.9 141.7 268.8 141.7 120.7 0 258.1-124.6 271.6-144.4 9.1-13.2 27.3-17.5 40.9-8.8 13.5 8.6 18.1 25.8 10 39.6-14.1 23.7-168.8 173-322.5 173z"
                      p-id="2631"></path>
                </svg>
                <svg v-else t="1644397194443" style="width: 20px;height: 20px" class="icon" viewBox="0 0 1024 1024"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="2770" width="200" height="200"><path
                    d="M512 832c-156.448 0-296.021333-98.730667-418.410667-291.605333a52.938667 52.938667 0 0 1 0-56.789334C215.978667 290.730667 355.552 192 512 192c156.448 0 296.021333 98.730667 418.410667 291.605333a52.938667 52.938667 0 0 1 0 56.789334C808.021333 733.269333 668.448 832 512 832z m0-576c-129.514667 0-249.461333 83.850667-360.117333 256C262.538667 684.149333 382.485333 768 512 768c129.514667 0 249.461333-83.850667 360.117333-256C761.461333 339.850667 641.514667 256 512 256z m0 405.333333c-83.210667 0-150.666667-66.858667-150.666667-149.333333S428.789333 362.666667 512 362.666667s150.666667 66.858667 150.666667 149.333333S595.210667 661.333333 512 661.333333z m0-64c47.552 0 86.101333-38.208 86.101333-85.333333S559.552 426.666667 512 426.666667c-47.552 0-86.101333 38.208-86.101333 85.333333s38.549333 85.333333 86.101333 85.333333z"
                    p-id="2771"></path></svg>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="130" label="所属城市">
        <template #default="scope">{{ scope.row.regionName != null ? scope.row.regionName : '' }}</template>
      </el-table-column>
      <el-table-column width="160" label="所属校区">
        <template #default="scope">{{ scope.row.campusName != null ? scope.row.campusName : '' }}</template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" prop="coachName" label="提交人" width="100"></el-table-column>
      <el-table-column show-overflow-tooltip="true" label="审核人" width="100">
        <template #default="scope">{{ scope.row.auditorName != null ? scope.row.auditorName : '待审核' }}</template>
      </el-table-column>
      <el-table-column width="110" align="center" label="沟通截图">
        <template v-slot="scope">
          <el-image class="code"
                    :src="scope.row.img != null? JSON.parse(scope.row.img)[0]:null"
                    :preview-src-list="scope.row.img != null?JSON.parse(scope.row.img):null">
            <template #error>
              <div class="code">
              </div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" prop="note" label="延期原因" width="180">
        <template #default="scope">
          <div :class="scope.row.note == '' ? 'msg':''">
            {{ scope.row.note != "" ? scope.row.note : '暂无备注' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="订单状态" width="110">
        <template v-slot="products">
          <el-tag :type="products.row.state == '已完成'? 'success': products.row.state == '已拒绝'? 'danger':products.row.state == '停用中'? 'warning':'info'">
            {{ products.row.state }}
          </el-tag>

        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" prop="createTime" label="创建时间" width="160"></el-table-column>
      <el-table-column show-overflow-tooltip="true" label="结束时间" width="160">
        <template #default="scope">
          <div :class="scope.row.postponeTime == null ? 'msg':''">
          {{ scope.row.postponeTime != null ? scope.row.postponeTime : '暂无时间' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" label="审核时间" width="160">
        <template #default="scope">
          <div :class="scope.row.auditorTime == null ? 'msg':''">
            {{ scope.row.auditorTime != null ? scope.row.auditorTime : '暂无时间' }}
          </div>

        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" fixed="right" align="center">
        <template #default="scope">
          <el-popover
              placement="left"
              :width="300"
              trigger="click">
            <div>
              <div style="display:flex;justify-content: center">
                <el-date-picker size="small"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
                                style="width:160px" v-model="update.startTime"
                                type="date" placeholder="开始日期">
                </el-date-picker>
                <el-date-picker size="small"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
                                style="width:160px" v-model="update.time"
                                type="date" placeholder="结束日期">
                </el-date-picker>
              </div>
              <div style="font-size: 12px;color: red;">
                <div style="margin: 18px 0 6px 0 ">不填写：将以用户第一次上课为结束日期。</div>
                <div>填写 ： 用户第一次上课最长延期为截止日期。</div>
              </div>
              <div style="text-align: right; margin: 0;padding-top: 20px">
                <el-button size="small" type="primary" @click="confirmStopOrder(scope,true)">确定</el-button>
              </div>
            </div>
            <template #reference>
              <el-button :disabled="scope.row.state!='待审核'" type="text">通过</el-button>
            </template>
          </el-popover>
          <el-popconfirm title="确定拒绝该订单延期吗？" @confirm="confirmStopOrder(scope,false)">
            <template #reference>
              <el-button :disabled="scope.row.state!='待审核'" type="text">拒绝</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {getStopOrder,updateStopOrderState} from "@/api/orders";
import selectArea from "../../components/SelectArea";
export default {
  components: {
    selectArea
  },
  name: "stopOrder",
  data() {
    return {
      query:{
        pageIndex:0,
        pageSize:10,
        areaList:[],
        coachName:"",
        studentName:""
      },
      update:{
        id:null,
        state:"",
        time:null,
        startTime:null
      },
      campusList:[],
      regionList:[],
      stopOrderList:[],
      tableLoading:false,
      notEncryptId:null,
      pageTotal:0
    }
  },
  methods: {
    getAreaListId(val) {
      this.query.areaList = val;
    },
    confirmStopOrder(val,state){
      this.update.id= val.row.id;
      this.update.state = state;
      if (this.update.time!=null){
        this.update.time = this.dateFormat(this.update.time)
      }
      if (this.update.startTime!=null){
        this.update.startTime = this.dateFormat(this.update.startTime)
      }
      updateStopOrderState(this.update).then(val=>{
        if (val.code==200){
          this.$message.success("审核成功！");
          this.getData();
        }else {
          this.$message.error("审核失败！");
        }
      })
    },
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },
    getData(){
      this.tableLoading= true;
      getStopOrder(this.query).then(val=>{
        this.tableLoading= false;
        if (val.code== 200){
          this.stopOrderList= val.data.records;
          this.pageTotal= val.data.total;
        }
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    },
    // 选中城市  渲染校区
    selectCity1(val) {
      this.query.campusId = null
      if (JSON.stringify(this.regionList[val])) {
        this.campusList = JSON.parse(JSON.stringify(this.regionList[val])).campuses;
      }
    },
    //手机号隐蔽
    viewPhone(val) {
      if (val == this.notEncryptId) {
        this.notEncryptId = "";
      } else {
        this.notEncryptId = val;
      }
    },
    filterTel(val) {
      //加密手机号
      if (val) {
        return (val.substring(3, 0)) + '****' + (val.substring(7));
      }
    },//赠卡
  },
  created() {
    this.getData();
  }
}
</script>

<style scoped>
.handle-select {
  width: 120px;
}
.mr10 {
  margin-right: 10px;
}
.table {
  width: 100%;
  font-size: 14px;
}
.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #efefef;
}
.msg{
  color: #C0C4CC;
}
</style>